import React from 'react'
import styled from 'styled-components'
import { Parallax } from 'react-scroll-parallax'
import Shape from './Shape'

const ParallaxShape = ({ x, y, shapeStyle }) => (
  <StyledParallaxShape>
    <Parallax className="parallax" x={[x, x]} y={[y, y + 50]} tagOuter="figure">
      <Shape shapeStyle={shapeStyle} />
    </Parallax>
  </StyledParallaxShape>
)

const StyledParallaxShape = styled.section`
  z-index: 1;
  .parallax {
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .parallax-inner {
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

export default ParallaxShape
export { StyledParallaxShape }
